import { Container, Col, Row, Tab, Nav, Image } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import truckDronePlot from '../assets/img/30-Customer-plot.png';
import truckDroneGif from '../assets/gif/30C-1T-3D-1_25SR-50LIM.gif';
import colorSharp2 from "../assets/img/color-sharp2.png";
import pythonLogo from '../assets/img/python-logo.png';
import gurobiLogo from '../assets/img/gurobiLogo.png';
/*import pyTorchLogo from '../assets/img/pyTorch-logo.png';*/

export const Projects = () => {

    const projects = [
        {
            title: "Truck & Drone delivery",
            topic: "Operations Research",
            software: [{
                        name: 'Python',
                        imgURL: pythonLogo,
                        },
                        {
                            name: 'Gurobi',
                            imgURL: gurobiLogo,
                        }],
            webpage: "/truck-drone-delivery",
            description: "Use a combination of trucks and drones to deliver packages to customers in the fastest time possible",
            imgUrl: truckDronePlot,
            gifURL: truckDroneGif,
        },
        {
            title: "Chess Engine",
            topic: "Algorithms",
            software: [{
                name: 'C++',
                imgURL: pythonLogo, // TODO: Put a C++ logo here
            }],
            webpage: "/chess",
            description: "Utilize a minimax algorithm written & optimised in C++ to develop a chess engine",
            imgURL: "",//truckDronePlot, // TODO: Get a chess image
            gifURL: "",//truckDroneGif, // TODO: Get a chess gif
        }
    ];

    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col>
                        <h2>Projects</h2>
                        <p>A collection of projects focusing on optimisation</p>
                        {
                        projects.map((project, index) => {
                                            return (
                                                <ProjectCard
                                                    key={index}
                                                    {...project}
                                                    />
                                            )
                                        })
                        }
                    </Col>
                </Row>
            </Container>
            <img className='background-image-right' src={colorSharp2} alt='Background Image Projects'></img>
        </section>
    )
}