import './ConnectFour'

export const Game = () => {
    return (
        <div>
            <h1>This is the GAME section</h1>
            <div id="board"></div>
        </div>
    )
}


