

export const ChessExplanation = () => {
    return (
        <div className="ChessExplanation-container">
            <div className="strength-heading">
                <h2>Strength: ~1800</h2>
            </div>
            <div className="explanation-heading">
                <h2>Brief Explanation</h2>
                <p>
                    This chess engine was written from scratch using a <strong>minimax</strong> algorithm. 
                    This assigns a score to the game with 1 player trying to maximise while the other is trying to mimimise this score (giving the name 'minimax').
                    There are two components of this algorithm, a function to evaluate a position without considering future moves, then a search method to investigate different moves. 
                </p>
                <br/>
                <p>
                    In a given position, the engine will consider the possible moves and take the resulting position with the best score (for the player's objective) and assign the current position that value. 
                    This process can occur recursively, allowing the evaluation of a position considering different move sequences. 
                    Because the number of possible positions is too significant, the move sequences cannot be extended to end of game every time, meaning the move sequence will result in a position where the game is still in play, requiring a new method to evaluate the position. 
                    This evaluation will look at things such as number of pieces on the board, king safety, strength of pawns and more but most importantly does not consider future moves in its evaluation. 
                </p>
                <br/>
                <p>
                    Because we are limited in the time that can be used to evaluate a position and find the optimal move, optimising the code so that more positions can be explored & evaluated in a shorter time frame. 
                    To accomplish this, the engine is written in C++ to improve performance with implementation considerations such as bitboards (representing the board with a series of numbers to use bitshifting and masking). 
                </p>
            </div>
        </div>
    )
}