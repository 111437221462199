import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";

import { ChessBoardComponent } from './ChessBoardComponent';
import { ChessExplanation } from './ChessExplanation';


export const Chess = () => {

    return (
        <div>
            <NavBar />
            <ChessBoardComponent />
            <ChessExplanation />
            <Footer />
        </div>
    );
}