import React, { useState, useEffect } from 'react';
import { Chessboard } from 'react-chessboard';
import Switch from './components/switch/Switch';
import SliderHeading from './components/SliderHeader/SliderHeader';

const { Chess } = require('chess.js')

export const ChessBoardComponent = () => {
  const [boardOrientation, setBoardOrientation] = useState('white');
  const [game, setGame] = useState(new Chess());
  const [turn, setTurn] = useState('Player');
  const [winner, setWinner] = useState('');
  const [prelimPlayerColour, setPrelimPlayerColour] = useState('white');
  const [playerColour, setPlayerColour] = useState('white');
  const [prelimComputerLevel, setPrelimComputerLevel] = useState('beginner');
  const [computerLevel, setComputerLevel] = useState('beginner');

  const startNewGame = () => {
    const newGame = new Chess();
    setGame(newGame);
    setPlayerColour(prelimPlayerColour);
    setComputerLevel(prelimComputerLevel);
    (playerColour === 'white') ? setTurn('Player') : setTurn('Computer');
    setBoardOrientation(playerColour);
    console.log('Game has now started');
  };

  const onPieceDrop = (sourceSquare, targetSquare) => {
    // Don't move if not turn
    if ((game.turn() === 'w' && playerColour !== 'white') ||
        (game.turn() === 'b' && playerColour !== 'black') ||
        turn !== 'Player') {
            return false;
    }
    // Chess.js move
    const move = game.move({
        from: sourceSquare,
        to: targetSquare
    });
    // Illegal move
    if (move === null) return false;

    // Update game state
    setGame(prev => new Chess(prev.fen()));

    // Check if player checkmated computer
    if (game.moves().length === 0) {
        setWinner('Player');
        setTurn('');
    } else {
        setTurn('Computer');
    }

    return true; // return true to accept the move
  };

  const computerMove = () => {
    // Check if game is still going
    if (game.isGameOver()) { return false; }

    // Retrieve computer move (API call)
    const apiURL = 'https://mattbungate.com/move/'; // TODO: BAD - put this into .env.production
    //const apiURL = `${process.env.REACT_APP_MOVE_API_URL}`;
    const data = { 
      "fen": game.fen(),
      "level": computerLevel
    };
    //fetch(`${process.env.REACT_APP_API_BASE_URL}/move`, {
    fetch(apiURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
        console.log("API RESPONSE");
        console.log(data);
        const move = game.move({
            from: data.sourceSquare,
            to: data.targetSquare,
            promotion: data.promotion
        });
        // Check illegal move
        if (move === null) {
            // Make a random move
            const moves = game.moves()
            const move = moves[Math.floor(Math.random() * moves.length)]
            game.move(move)
        }
        // Update variables
        setGame(prev => new Chess(prev.fen()));
        // Check if computer checkmated Player
        if (game.moves().length === 0) {
            setWinner('Computer');
            setTurn('');
        } else {
            setTurn('Player');
        }
    })
    .catch((error) => {
        console.error('Error fetching from API:', error);
    });
  };

  useEffect(() => {
    if (turn === 'Computer') {
        computerMove();
    }
  }, [turn]);
  /* 
  Slider code

  <SliderHeading firstHeading='Beginner' secondHeading='Advanced'/>
  <Switch isToggled={!isBeginner} onToggle={() => setIsBeginner(!isBeginner)} />
  */

  return (
    <div style={{ maxWidth: '600px', margin: 'auto' }} className='Chess-board'>
        <h1>Chess Engine</h1>
        <div className="button-group">
          <button className={`chess-button ${prelimPlayerColour == 'white' ? 'selected' : ''}`} onClick={() => setPrelimPlayerColour('white')}>Play as White</button>
          <button className={`chess-button ${prelimPlayerColour == 'black' ? 'selected' : ''}`} onClick={() => setPrelimPlayerColour('black')}>Play as Black</button>
        </div>
        <div className="button-group">
          <button className= {`chess-button ${prelimComputerLevel == 'beginner' ? 'selected' : ''}`} onClick={() => setPrelimComputerLevel('beginner')}>Beginner</button>
          <button className= {`chess-button ${prelimComputerLevel == 'advanced' ? 'selected' : ''}`} onClick={() => setPrelimComputerLevel('advanced')}>Advanced</button>
        </div>
        <button className='chess-button selected' onClick={() => startNewGame()}>Start Game</button>
      <div className="chess-board-group">
        <Chessboard
          className="board"
          id="BasicChessboard"
          position={game.fen()}
          boardOrientation={boardOrientation}
          onPieceDrop={onPieceDrop}
          boardWidth={ Math.min(window.innerWidth * 0.9, 600) }
        />
      </div>
      <div className="button-group">
        <button className='chess-button selected' onClick={() => setBoardOrientation(boardOrientation === 'white' ? 'black' : 'white')}>
          Flip Board
        </button>
      </div>
      <div className="turn-or-winner">
        <h3>{winner === '' ? `Turn: ${turn}` : `Winner: ${winner}`}</h3>
      </div>
      
    </div>
  );
}
